* {
    box-sizing: border-box;
}

body, html, #root {
    min-height: 100%;
    height: 100%;
}

.alert {
    border: 1px solid lightsalmon;
    color: lightsalmon;
    padding: 25px;
    margin-bottom: 25px;
}

a {
    text-decoration: none;
}

iframe {
    width: 100%;
}